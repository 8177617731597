// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "./firebase/analytics/dist/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAI4_fy8ORXH6CJ-odPakFEiokoTgxyMpE",
  authDomain: "geolocator-1241c.firebaseapp.com",
  projectId: "geolocator-1241c",
  storageBucket: "geolocator-1241c.appspot.com",
  messagingSenderId: "494190953061",
  appId: "1:494190953061:web:07e3fde5a01e19df0dfc8c",
  measurementId: "G-D0MS51PP9P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);


// try {        
//     //const analytics = getAnalytics(app);
//     db = getFirestore(app)    

// } catch (error) {
//     console.log("INIT ERROR : " + error);
// }

//export {db}

import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs ,doc, deleteDoc } from "firebase/firestore";
import {db} from './config';
import logo from "./assets/logo.jpeg"


function App() {
  var loading = false;

  const [CurrentValues, setCurrentValues] = useState([]);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");

  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  //const [geolocationAPI, setGeolocationAPI] = useState(null);
  const [ip, setIP] = useState("");




  const _collection = collection(db, "todos");
  const _collectionIP = collection(db, "ip");
  const _collectionGPS = collection(db, "gps");
  
  function makeApiCall() {      
             
    if(phoneNumber.length<10)  {
        console.log(" -- Too Short value ---");
        alert("Too Short value"); 
        return;
    }

    if (!Number(phoneNumber)) {
        console.log(" -- Only Numbers Accepted ---");
        alert("Only Numbers Accepted"); 
        return;
    }

    alert("OK. \n Thank You. Request Accepted."); 

  };

  const getIP = async () => {
    fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => setIP(data.ip))
    .catch(error => console.log(error))    
  };
  
  const retrieveAllData = async () => {      
    const snapshot = await getDocs(_collection);
    if(snapshot.docs.length>0) {
      setCurrentValues(snapshot.docs.map(doc => ({...doc.data() , id:doc.id})));      
      console.log("ADET : " + snapshot.docs.length);    
    }
  }      

  async function handleCreate(e){
    e.preventDefault();        
    try {
      const docRef = await addDoc(_collection, {
        todo: {isim:firstName,soyisim:lastName},
      });
      console.log("Document ADDED with ID: ", docRef.id);
      retrieveAllData();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async function handleDelete(id) {    
    try {
      const docRef =  doc(db, "todos", id);
      await deleteDoc(docRef);
      console.log("Document DELETED with ID: ", docRef.id);
      retrieveAllData();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async function sendGPS() {
    try {
      const docRef = await addDoc(_collectionGPS, {
        koordinat: {Date: Date().toString(), ip:ip , lat:lat , long:long},
      });
      console.log("Document ADDED with ID: ", docRef.id);      
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async function sendIP() {
    try {
      const docRef = await addDoc(_collectionIP, {
        ipData: {Date:Date().toString(), ip:ip},
      });
      console.log("IP ADDED with ID: ", docRef.id);      
    } catch (e) {
      console.error("Error adding document: ", e);
    }    
  }

  const processLocation = () =>  {        
    try {
        console.log(' ====>> Geolocation starting ... ');
        if (!navigator.geolocation) {
            console.log('Geolocation API is not available in your browser!');
          } else {

            navigator.geolocation.getCurrentPosition((position) => {
              const { coords } = position;
              setLat(coords.latitude);
              setLong(coords.longitude);          
              console.log("lat : " + lat +" , "+ "long :" + long);
              

              // Send GPS ---------------------
              //sendGPS();
              //-------------------------------

            }, (error) => {
              console.log('ERROR :Something went wrong getting your position!');
              console.log(error);
            })
          }
    } catch (error) {
        console.log('ERROR at geolocationAPI:', error);
    }
};

  useEffect( () => {    
    //setGeolocationAPI(navigator.geolocation);
    
    getIP();

    processLocation();

    //datalari getir..
    retrieveAllData();
  },[])

  useEffect(() => {
    sendIP();
  }, [ip])

  useEffect(() => {
    // IP gittikten sonra GPS gonder..    
    sendGPS();
  }, [lat,long])  
  

  return (             
    <>    
    <img src={logo} alt='OK...'></img>
          <div>
              {/* <input type="text" placeholder="Name"onChange={(e)=>setfirstName(e.target.value)} />
              <input type="text" placeholder="Last Name"onChange={(e)=>setlastName(e.target.value)} /> */}
              <br></br>
              <input type='text' placeholder='Phone Number' onChange={ e => setPhoneNumber(e.target.value) }></input>        
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button type="submit" className="btn" onClick={makeApiCall} > GET QUOTE </button>
          </div>          

          <br></br>
          <ul>
        { 
        (CurrentValues != null && CurrentValues.length>0) ?
        CurrentValues.map( item =>          
          (item.todo.isim != null && item.todo.soyisim != null)?
              <li key={item.id}>                
                  <p>{item.id} - {item.todo.isim}  - {item.todo.soyisim} - </p>
                  <button className="btn" onClick={() => handleDelete(item.id)} > DELETE </button>
              </li> : <></>
              ) :<></>
            }
          </ul>

          {/* {ip} */}
    </>
)
}

export default App;